import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

import { withRouter } from "react-router-dom";

import Avatar from "react-avatar";
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileSettings from "./profile-sidebar";
import Select from "react-select";
import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../common/data/timezones";
import { uInfo, uToken, currentDateTime } from "../../useToken";
import { ApiUrl, AttachementsUrl, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import countries from "../../common/data/countries";
import { Link } from "react-router-dom";
const UserConstraintSetting = (props) => {
  const [tab, setTab] = useState("1");
  const [numbers, setNumber] = useState([]);
  const [holidays, setHolidays] = useState({});
  const [constraintSettings, setConstraintSettings] = useState({});

  useEffect(() => {
    getPhoneNumbers();

  }, []);

  function toggle(index) {
    setTab(index);
  }

  const getPhoneNumbers = () => {
    fetch(`${ApiUrl}` + "user/form/data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setNumber(response.data.phones);
          setConstraintSettings(response.data.constraint_settings);
          setHolidays(response.data.holidays);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }



  const handleSubmitConstraint = async (event, values) => {
    if (values.holidays && values.skip_weak_days) {
      values.holidays = values.holidays.toString();
      values.skip_weak_days = values.skip_weak_days.toString();
    }

    fetch(`${ApiUrl}` + "user/constraint/setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


    return (
      <React.Fragment>
        <Helmet>
          <title>Global Constraint Settings</title>
        </Helmet>
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={props} />
          </Col>

          <Col lg={10}>
          <Row>
              <Col sm={12}>
                <div className="page-title-box">
                  <h4> Global Constraint Settings</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                    Settings
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> Constraint Setting</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <AvForm
                  onValidSubmit={handleSubmitConstraint}
                  className="needs-validation"
                >
                  <ModalBody className="row">
                    <p> Current Date / Time: {currentDateTime()} </p>
                    <div className="col-md-8 pl-25 mb-3">
                      <div className="row time-constraint">
                        <div className="col-md-12 mb-3">
                          <AvField
                            name="block_days"
                            value={constraintSettings.block_days}
                            label="Block Days"
                            className="form-control"
                            placeholder="For Example: 2022-04-16,2022-04-17"
                          />
                          <div className="note">
                            Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <AvField
                            name="from_time"
                            value={constraintSettings.from_time}
                            label="From Time"
                            className="form-control"
                            required
                            type="time"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <AvField
                            name="to_time"
                            value={constraintSettings.to_time}
                            label="To Time"
                            className="form-control"
                            required
                            type="time"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            name="skip_weak_days"
                            label="Skip Week Days"
                            multiple
                            value={constraintSettings.skip_weak_days}
                          >
                            <option value="Monday"> Monday </option>
                            <option value="Tuesday"> Tuesday </option>
                            <option value="Wednesday"> Wednesday </option>
                            <option value="Thursday"> Thursday </option>
                            <option value="Friday"> Friday </option>
                            <option value="Saturday"> Saturday </option>
                            <option value="Sunday"> Sunday </option>
                          </AvField>
                        </div>
                        <div className="col-md-6 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            value={constraintSettings.holidays}
                            name="holidays"
                            label="USA Holidays"
                            multiple
                          >


                            {Object.entries(holidays).map(
                              ([key, value]) => {
                                return <option key={key} value={key}> {value} </option>;
                              }
                            )}
                          </AvField>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                    >
                      Submit
                    </button>
                  </ModalFooter>
                </AvForm>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
};

export default UserConstraintSetting;
