import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
    convertPhoneFormat,
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import { Asia, TimezoneMain, America, Australia, Europe, Indian } from "../../common/data/timezones";
import swal from 'sweetalert';
export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
            page_name: "Contact Us",
            from: this.props.from ? this.props.from : '',
            record: {
                "from_time": "08:00:00",
                "to_time": "18:00:00",
                "throttle_duration": "15",
                "throttle_value": "5",
            },
            showAfter: false,
            groups: [],
            tags: [],
            holidays: {},
            managers: [],
            group_id: [],
            tag_id: [],
            emails: [],
            phoneNumbers: [],
            permission: [],
            country_code: [],
            CustomField: [],
            customFieldData: {},
            main: 'Asia',
            Timezone: TimezoneMain,
            zones: Asia,
            zone: 'Asia/Aden'
        };
    }

    componentDidMount() {
        //this.state.id != null && this.getData();
        this.getFormData();
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/campaign/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        groups: response.data.groups,
                        tags: response.data.tags,
                        holidays: response.data.holidays,
                        emails: response.data.emails,
                        phoneNumbers: response.data.phoneNumbers,
                        permissions: response.data.coachPartners,
                        record: response.data.constraint_settings
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleSubmit = async (event, values) => {
        swal({
            text: "Your contact request has been successfully submitted. Our support team will reach out to you shortly.",
            icon: "success",
        });
        return false;
        if (this.state.id) {
            values.id = this.state.record.campaign_id ? this.state.record.campaign_id : -1;
        }

        values.holidays = values.holidays.toString();
        values.skip_weak_days = values.skip_weak_days.toString();

        fetch(`${ApiUrl}` + "add/campaign", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {

                if (data.status === true) {
                    setTimeout(
                        () => this.props.history.push('/campaign/list'),
                        1000
                    );

                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleTag = (selectedTags) => {
        var filter = [];
        if (selectedTags) {
            selectedTags.map(fil => {
                filter.push(fil.group_id);
            })
        }

        this.setState({
            tag_id: filter.toString(),
            selectedTags: selectedTags
        })

    }

    handleGroup = (selectedGroup) => {

        var filter = [];
        if (selectedGroup) {
            selectedGroup.map(fil => {
                filter.push(fil.group_id);
            })
        }

        this.setState({
            group_id: filter.toString(),
            selectedGroup: selectedGroup
        })
    }

    handlePermission = (selectedPermission) => {

        var filter = [];
        if (selectedPermission) {
            selectedPermission.map(fil => {
                filter.push(fil.user_id);
            })
        }

        this.setState({
            permission: filter.toString(),
            selectedPermission: selectedPermission
        })
    }

    changeTimeZone = (zone) => {
        this.setState({
            zone: zone
        })
    }

    changeMainZone = (zone) => {
        if (zone == 'Asia') {
            this.setState({
                zones: Asia,
                zone: Asia[0].id
            })
        } else if (zone == 'America') {
            this.setState({
                zones: America,
                zone: America[0].id
            })
        } else if (zone == 'Australia') {
            this.setState({
                zones: Australia,
                zone: Australia[0].id
            })
        } else if (zone == 'Europe') {
            this.setState({
                zones: Europe,
                zone: Europe[0].id
            })
        } else if (zone == 'Indian') {
            this.setState({
                zones: Indian,
                zone: Indian[0].id
            })
        }
        this.setState({
            main: zone,
        })

    }

    render() {
        return (
            <React.Fragment>


                <Helmet>
                    <title> {this.state.page_name} </title>
                </Helmet>
                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                  
                </Row>
                <ToastContainer />

                <Row>
                    
                    <Col className="col-12 p-3">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">
                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="title"
                                            value={this.state.record.title}
                                            label="Your Name"
                                            placeholder="Please enter your name"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                     <div className="col-md-12 mb-3">
                                        <AvField
                                            name="title"
                                            value={this.state.record.title}
                                            label="Your Email"
                                            placeholder="Please enter your email"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                     <div className="col-md-12 mb-3">
                                        <AvField
                                            name="title"
                                            value={this.state.record.title}
                                            label="Your Message"
                                            placeholder="Please enter your name"
                                            className="form-control"
                                            type="textarea"
                                            required
                                        />
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        className="btn btn-primary w-md waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>

                </Row>
            </React.Fragment >
        );
    }
}
