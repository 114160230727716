import React, { useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { hasRole, uInfo, uRole } from "../../useToken";

const CoachSidebar = (props) => {
  const ref = useRef();
  let history = useHistory();
  useEffect(() => {

    const pathName = window.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [window.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
    checkVerfication();
  }, []);
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
const checkVerfication = () => {
    if (uInfo().is_initial_password_change === 0) {
        setTimeout(history.push("/change/initial/password"), 1000);
    }
  }
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
  <React.Fragment>
    <SimpleBar className="mt-1" style={{ maxHeight: "100%" }} ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">

          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="mdi mdi-view-dashboard"></i>
              <span>{"Real Time Report"}</span>
            </Link>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="fa fa-bullhorn"></i>
              <span>{"Campaign"}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/campaign/list" className="waves-effect">
                  <i className="fa fa-list-alt"></i>
                  <span>{"Campaign"}</span>
                </Link>
              </li>
              <li>
                <Link to="/caller/numbers/list" className="waves-effect">
                  <i className="fa fa-id-badge"></i>
                  <span>{"Caller ID"}</span>
                </Link>
              </li>
              <li>
                <Link to="/callstatus/list" className="waves-effect">
                  <i className="fa fa-phone-square-alt"></i>
                  <span>{"Call Disposition"}</span>
                </Link>
              </li>
              <li>
                <Link to="/agent/script/list" className="dropdown-item">
                  <i className="fa fa-file-alt"></i>
                  <span>{"Agent Scripts"}</span>
                </Link>
              </li>
              <li>
                <Link to="/call/log" className="dropdown-item">
                  <i className="fa fa-history"></i>
                  <span>{"Call Logs"}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="fa fa-list-ul"></i>
              <span>{"List"}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/contact/list" className="waves-effect">
                  <i className="fa fa-address-book"></i>
                  <span>{"Contacts"}</span>
                </Link>
              </li>
              <li>
                <Link to="/phone/list" className="waves-effect">
                  <i className="fa fa-phone"></i>
                  <span>{"Phone List"}</span>
                </Link>
              </li>
              <li>
                <Link to="/recycle/option" className="waves-effect">
                  <i className="fa fa-recycle"></i>
                  <span>{"Recycle"}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="fa fa-user-friends"></i>
              <span>{"Agents"}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/agents/list" className="waves-effect">
                  <i className="fa fa-user"></i>
                  <span>{"Agents"}</span>
                </Link>
              </li>
              <li>
                <Link to="/teams/list" className="waves-effect">
                  <i className="fa fa-users"></i>
                  <span>{"Teams"}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/settings/billing" className="waves-effect">
              <i className="fas fa-file-invoice-dollar"></i>
              <span>{"Billing"}</span>
            </Link>
          </li>

          <li>
            <Link to="/contact/support" className="waves-effect">
              <i className="fas fa-headset"></i>
              <span>{"Support"}</span>
            </Link>
          </li>
        </ul>
      </div>
    </SimpleBar>
  </React.Fragment>
);

};

export default CoachSidebar;
