import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import ReactDatatable from '@ashvin27/react-datatable';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { dcrypt, getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../../useToken";
import { ApiUrl, ProductName,ToastAutoClose} from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
// import '../../../node_modules/bootstrap/js/dist/tooltip';

import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


export default class EmailCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
      record: {
        title: '',
        body: '',
      },
      editor: '',
      page_name: this.props.match.params.id ? `View` : `Create`,
      name: this.props.match.params.path == 'disclaimer' ? 'Disclaimer' : this.props.match.params.path == 'termsofservice' ? 'Terms of Service' : 'Privacy Policy',
    }
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.state.id != null && (
      this.getData()
    )
  }

  getData = () => {
    fetch(`${ApiUrl}` + 'view/content', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({ id: this.state.id, type: this.props.match.params.path }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, 'data')
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            // inputList: JSON.parse(response.data.record.description)
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

  }

  handleSubmit = async (event, values) => {
    values.body = this.state.editor;
    values.type = this.props.match.params.path;
    values.page_name = this.state.name;
    fetch(`${ApiUrl}` + 'add/content', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.setState({
              editor: '',
            })
            this.form && this.form.reset();
          }
          this.props.history.goBack();
          // this.getData();
        //  toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  handleChange(content) {
    this.setState({
      editor: content
    })
  }


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} {this.state.name} </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name} {this.state.name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name} {this.state.name}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>

            </div>
          </Col>
        </Row>


        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm onValidSubmit={this.handleSubmit} model={this.state.record} ref={c => (this.form = c)} className="needs-validation">
                <ModalBody>


                  <div className="mb-3">
                    <AvField
                      name="title"
                      value={this.state.record.title}
                      label="Title"
                      className="form-control"
                      required
                    />
                  </div>


                  {
                    this.state.record.id ? (
                      <div className="mb-3">
                        <SunEditor
                          setOptions={{
                            height: 400,
                            buttonList: [
                              ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                              ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                              ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview']
                            ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          defaultValue={this.state.record.body}
                          onChange={this.handleChange}
                        />
                      </div>
                    ) :
                      <SunEditor
                        setOptions={{
                          height: 400,
                          buttonList: [
                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                            ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview']
                          ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        setContents={this.state.editor}
                        onChange={this.handleChange}
                      />


                  }
                  <AvField
                    name="body"
                    value={this.state.editor}
                    type="hidden"
                    required
                  />





                </ModalBody>

                {
                  this.state.id == null && (
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button className="btn btn-primary waves-effect waves-light" type="submit">Submit</button>
                    </ModalFooter>
                  )
                }
              </AvForm>
            </Card>
          </Col>
        </Row>

        {/* /////////////////////////////////////////////// */}


      </React.Fragment >
    )
  }
}

