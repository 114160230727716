import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    bcrypt,
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"

import { currencies } from '../../common/Common-Currency';
import AsyncSelect from 'react-select/async';
export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            currency_arr: [],
            module_set_arr: {},
            currency_code: "USD",
            clients: [],
            client_id: this.props.client_id ? this.props.client_id : 0,
        };
    }
    // selectCurrency(e){
    //     const name = e.target.name;
    //     const val = e.target.value;
    //     this.setState({
    //         currency: prevState => ({ ...prevState, [name]:val })
    //     });
    //     console.log(currencies, 'currency');
    // };


    componentDidMount() {
        this.getFormData();
        this.getCurrency();
    }
    getCurrency = () => {
        var currency_arr = [];
        Object.keys(currencies).map(item => {
            currency_arr.push({ 'label': currencies[item].symbol + '- (' + currencies[item].name_plural + ') (' + currencies[item].code + ')', 'value': item });

        })


        this.setState({ currency_arr: currency_arr });
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/assessment/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        clients: response.data.clients
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    handleSubmit = async (event, values) => {
        if (this.state.client_id != '') {
            values.client_id = this.state.client_id;
        }

        fetch(`${ApiUrl}` + "add/assessment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }

                    if(this.props.from != true){
                        this.props.props.history.push(`/assessment/${bcrypt(data.data.assessment_id)}/summary`)
                    }

                    //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    this.props.cancelSlider();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleCurrency = (selectedCurrency) => {
        console.log(selectedCurrency);
        this.setState({
            currency_code: selectedCurrency.value
        })
    }

    handleInputChange = (inputValue) => {
        // const inputValue = newValue.replace(/\W/g, '');
        if (inputValue.length > 3) {
            fetch(`${ApiUrl}` + "search/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },

                body: JSON.stringify({ search: inputValue }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            clients: response.data.clients
                        });
                    } else {
                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        };
        console.log(inputValue.length);
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <ToastContainer />
                    <Col className="col-12">
                        <AvForm
                            onValidSubmit={this.handleSubmit}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                        >
                            <ModalBody className="row">
                                <div className="col-md-12 mb-3">
                                    <AvField
                                        name="name"
                                        value={this.state.record.name}
                                        label="Name"
                                        className="form-control"
                                        required
                                    />
                                </div>


                                {
                                    this.props.from === true && (
                                        <div className="col-md-12 mb-3">
                                            <label className="control-label">Client</label>
                                            <div className="MainTZone Assessment One">
                                                <Select
                                                    getOptionLabel={option => option.client_id + ' # ' + option.first_name + ' ' + option.last_name + ((option.email != '') ? ' - ' + option.email : ' - ' + option.country_code + option.mobile)}
                                                    getOptionValue={option => option.client_id}
                                                    isMulti={false}
                                                    value={this.state.clients.filter(({ client_id }) => client_id === this.state.client_id)}
                                                    options={this.state.clients}
                                                    classNamePrefix="select2-selection"
                                                    onInputChange={this.handleInputChange}
                                                    onChange={(e) => { this.setState({ client_id: e.client_id }); }}
                                                />
                                            </div>
                                            <AvField required type="hidden" name="adc" value={this.state.client_id} />
                                        </div>
                                    )
                                }


                                <div className="col-md-12 mb-3">
                                    <label className="control-label">Currency</label>
                                    <div className="MainTZone">
                                        <Select
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            isMulti={false}
                                            options={this.state.currency_arr}
                                            value={this.state.currency_arr.filter(({ value }) => value === this.state.currency_code)}
                                            onChange={(e) => {
                                                this.handleCurrency(e)
                                            }}
                                            classNamePrefix="select form-control"
                                            className={"is-touched is-dirty av-valid"}
                                        />
                                    </div>
                                    <AvField type="hidden" name="assessment_currency" value={this.state.currency_code} />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="select"
                                        name="module"
                                        label="Module Set"
                                        required
                                        value="6"
                                    >
                                        {/* <option value="">Select Module Set</option> */}
                                        {/* <optgroup label="Profit Acceleration">
                                            <option value="1">Jumpstart 12  - To close a sale</option>
                                            <option value="2">Jumpstart 40 (To close a sale or perform a faster full assessment)</option>
                                            <option value="3">Deep Dive 40 - To perform a full assessment</option>
                                        </optgroup>
                                        <optgroup label="Digital Acceleration">
                                            <option value="4">Jumpstart (To close a sale with a digital marketing focus)</option>
                                            <option value="5">Deep Dive - To perform a full assessment with a digital marketing focus</option>
                                        </optgroup> */}
                                        <option value="6">Pathway to Profit</option>
                                    </AvField>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </ModalFooter>
                        </AvForm>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
