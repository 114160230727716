import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import swal from 'sweetalert';

export default class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      page_name: "Attach Phone List",
      dispositions: [],
      groups: [],
      campaigns: [],
      selectedGroup: null,
      selectedCampaign: null,
    };
  }

  componentDidMount() {
    this.getMeta();
    if (this.state.id) {
      this.getData(this.state.id);
    }
  }

  getData = (id) => {
    fetch(`${ApiUrl}get/campaign/phonelist`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          const record = response.data.record;
          this.setState({
            record,
            selectedGroup: record,
            group_id: record.group_id,
            group: record.group_id,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error'
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getMeta = () => {
    fetch(`${ApiUrl}get/recycle/meta`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          this.setState({
            groups: response.data.records.groups,
            campaigns: response.data.records.campaigns,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    fetch(`${ApiUrl}assign/phone/list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          swal({
            text: "Phone list assigned successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error'
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleGroup = (selectedGroup) => {
    this.setState({
      id: selectedGroup.group_id,
      group: selectedGroup.group_id,
      selectedGroup,
    }, () => {
      this.getData(selectedGroup.group_id);
    });
  };



  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} Tables</title>
        </Helmet>
        <Row>
          <Col className="Col-12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name}</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    className="needs-validation"
                  >
                    <ModalBody>
                      <div className="col-md-12">
                        <label>Phone List</label>
                        <div className="mb-3">
                          <Select
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.group_id}
                            isMulti={false}
                            options={this.state.groups}
                            classNamePrefix="select2-selection"
                            name="group_id"
                            value={this.state.selectedGroup}
                            onChange={this.handleGroup}
                          />
                        </div>
                        <AvField
                          required
                          type="hidden"
                          name="group_id"
                          value={this.state.group}
                        />
                      </div>
                      <div className="mb-3">
                      <AvField
                          className="select form-control"
                          type="select"
                          name="campaign_id"
                          label="Select Campaigns"
                          value={this.state.record.campaign_id}
                        >
                          <option value=""> Select Campaign </option>
                          {this.state.campaigns.map((row, i) => {
                            return (
                              
                                <option key={i} value={row.campaign_id}>
                                  {row.title}
                                </option>
                              
                            );
                          })}
                        </AvField>
                      
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </AvForm>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
