import { uInfo } from './useToken'; 
const HostName = (window.location.host == 'localhost:5001' || window.location.host == '192.168.0.102:5001') ?
    'http://localhost:8000/api/' :
    "https://api.jaidadbuilder.com/api/";
 export const ApiUrl = HostName;
const HostWebUrl = (window.location.host == 'localhost:5001' || window.location.host == '192.168.0.102:5001') ?
    'http://'+window.location.host :
    "https://calltov.jaidadbuilder.com";
export const HostUrl = HostWebUrl;

export const ToastAutoClose = 100;
export const PusherKey = "333333"; 
export const logoLightPng = require('./assets/images/logo.png');
export const logoLightSmallPng = require('./assets/images/logo-small.png');
export const ProductName = "CallTov";

export const AttachementsUrl = (window.location.host == 'localhost:5001' || window.location.host == '192.168.0.102:5001') ?
    'http://localhost:8000/crmdoc/' :
    "https://api.jaidadbuilder.com/crmdoc/";

const web = (window.location.host == 'localhost:5001' || window.location.host == '192.168.0.102:5001') ?
    'http://localhost:8000' :
    "https://api.jaidadbuilder.com";
export const WebUrl = web;

export const MeetingUrl = "https://meet.profit-hunt.app";



// fetch(`${HostName}fetchTemplate/` + uInfo().user_id, {
//         method: 'GET',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//     })
//     .then((response) => response.json())
//     //Then with the data from the response in JSON...
//     .then((response) => {
//         // console.log(response.theme);
//         localStorage.setItem("theme", response.theme);
//     })
//     //Then with the error genereted...
//     .catch((error) => {
//         console.error('Error:', error);
//     });

const greeting = `localStorage.getItem("theme")`;

let obj = {};

if (greeting) {
    let lines = greeting.split("\n");

    if (lines.length === 0) {
        throw new Error("Greeting is empty after split");
    }

    lines.map((string, index) => {
        if (string) {
            let part1Match = string.match('export const ' + "(.*?)" + '=');
            if (part1Match) {
                let part1 = part1Match[1].replace(/\s/g, '');
                let start = `export const ${part1} =`;
                let varValueMatch = string.match(start + "(.*?)" + ';');
                if (varValueMatch) {
                    let varValue = varValueMatch[1];
                    obj[part1] = varValue.replaceAll("'", '');
                } else {
                }
            } else {
            }
        }
    });
} else {
}


export const bgColor = obj.bgColor;
export const btnPrimaryBgColor = obj.btnPrimaryBgColor;
export const btnPrimaryBgHoverColor = obj.btnPrimaryBgHoverColor;
export const textColor = obj.textColor;
export const btnPrimaryTextColor = obj.btnPrimaryTextColor;
export const btnPrimaryHoverText = obj.btnPrimaryHoverText;
export const btnDangerTextColor = obj.btnDangerTextColor;
export const btnDangerBgColor = obj.btnDangerBgColor;
export const btnDangerHoverText = obj.btnDangerHoverText;
export const btnDangerBgHoverColor = obj.btnDangerBgHoverColor;
export const btnInfoTextColor = obj.btnInfoTextColor;
export const btnInfoBgColor = obj.btnInfoBgColor;

export const btnInfoHoverText = obj.btnInfoHoverText;
export const btnInfoBgHoverColor = obj.btnInfoBgHoverColor;
export const navTextColor = obj.navTextColor;
export const navTextHoverColor = obj.navTextHoverColor;
