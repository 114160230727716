import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Progress
} from "reactstrap";

import Helmet from "react-helmet";
import { bcrypt, uRole, uToken, uInfo, NumberWithCommas } from "../../useToken";
import { ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import CalanderView from "./calanderView";
import moment from 'moment';
import Select from "react-select";
import swal from 'sweetalert';

import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../common/data/timezones";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";

const Coach = (props) => {
  const [record, setRecord] = useState({
    new_leads: [],
    revenue_goals: {
      revenue_goals: {}
    },
    revenue_goals_yearly: {
      revenue_goals: {}
    },
    report_records: {
      all_results: [],
      report_results: [],
      email_report: [],
      sms_report: [],
      call_report: [],
      voice_report: [],
      voice_message_listen: [],
    },
    pipeline_stats: [],
    activity_log: [],
  });
  const [clients, setClients] = useState([]);
  const [client_id, setClientId] = useState(0);
  const [client, setClient] = useState(0);

  const [taskClientLog, setTaskClientLog] = useState(0);
  const [pipeline_status_month, setPipelineStatusMonth] = useState("");
  const [pipeline_stats, setPipelineStats] = useState([]);

  const [taskModal, setTaskModal] = useState(false);
  const [taskData, setTaskData] = useState({});

  const [eventModal, setEventModal] = useState(false);
  const [eventData, setEventData] = useState({});


  const [goalsModal, setGoalsModal] = useState(false);
  const [goalsArray, setGoalsArray] = useState(false);
  const [timeZone_data, setnewzone] = useState(Asia);
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  const [coachPartners, setCoachPartners] = useState([]);
  const [pipeline_list, setPipelineList] = useState([]);
  const [current_pipeline_id, setCurrentPipelineId] = useState([]);
  const [coachUser, setCoachUser] = useState({});
  const [calendarReload, setCalendarReload] = useState(true);

  const [activity, setActivity] = useState({
    report_results: [],
    sms_count: 0,
    email_count: 0,
    call_count: 0,
    voice_count: 0,
    voice_message_un_listen: 0
  });
  const [task_record, setTaskRecord] = useState({
    task_log: [],
    today: 0,
    weekly: 0,
    monthly: 0,
    unassigned: 0
  });
  const [schedule_record, setScheduleRecord] = useState({
    schedule_log: [],
    total_today: 0,
    total_week: 0,
    total_month: 0
  });
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const d = new Date();
  const [current_month] = useState(months[d.getMonth()]);
  let history = useHistory();
  useEffect(() => {
    let t1 = new moment();
    let month_year = t1.format("YYYY-MM");
    // setPipelineStatusMonth(month_year);
    // getData();
    // getActivity();
    // getPipeline();
    // getTaskStatistics("all");
    // getScheduleStatistics("all");
    // getCoachUsers();
  }, []);

  const getData = () => {
    fetch(`${ApiUrl}` + "dashboard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecord(response.data.record);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getCoachUsers = () => {
    fetch(`${ApiUrl}` + "get/coach/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setCoachPartners(response.data.coachPartners);
          setCoachUser(response.data.coachUser);
          setClients(response.data.clients);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPipeline = () => {
    fetch(`${ApiUrl}` + "get/master/pipeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPipelineList(response.data.records);
          setCurrentPipelineId(response.data.current_pipeline.pipeline_id);
          let t1 = new moment();
          let month_year = t1.format("YYYY-MM");
          getPipelineStats(month_year, response.data.current_pipeline.pipeline_id);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getPipelineStats = (year_month, pipeline_id) => {
    setPipelineStatusMonth(year_month);
    fetch(`${ApiUrl}` + "dashboard/dealstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'year_month': year_month, 'pipeline_id': pipeline_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPipelineStats(response.data.record);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getActivity = (type = 'all') => {
    fetch(`${ApiUrl}` + "get/business/inbox", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ day: 'today', 'type': type, 'client_id': 0 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setActivity(response.data.records);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 3) {
      fetch(`${ApiUrl}` + "search/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },

        body: JSON.stringify({ search: inputValue }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            setClients(response.data.clients);
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    console.log(inputValue.length);
  };
  const getTaskStatistics = (task_interval) => {
    return false;
    fetch(`${ApiUrl}` + "dashboard/task/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_interval: task_interval, client_id: 0 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setTaskRecord(response.data.record);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getScheduleStatistics = (task_interval) => {
    // console.log('scheudleee')
    // fetch(`${ApiUrl}` + "dashboard/schedule/statistics", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ` + uToken(),
    //   },
    //   body: JSON.stringify({ task_interval: task_interval }),
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (response.status === true) {
    //       //console.log(response.data.record.schedule_log, 'cccccccccccccc')
    //       // setScheduleRecord(response.data.record);
    //     } else {
    //                 

    //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];
  function settimezone(e) {
    setMainTimeZone(e);
    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }
    setTimeZone({});
    // console.log(timeZone_data,'Zone Change');
  }

  const handleTaskSubmit = async (event, values) => {
    setCalendarReload(false);
    fetch(`${ApiUrl}` + "add/task/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTaskModal(false);
          getTaskStatistics("monthly");
          setCalendarReload(true);
          setTaskData({});
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const handleGoalsSubmit = async (event, values) => {

    fetch(`${ApiUrl}` + "edit/user/goals/" + uInfo().user_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setGoalsModal(false);
          getData();
          getPipelineStats(pipeline_status_month, current_pipeline_id);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const deleteTaskAndEvents = (meeting_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          setCalendarReload(false);
          fetch(`${ApiUrl}` + "delete/meeting", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: meeting_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                setTaskModal(false);
                setTaskData({});
                getTaskStatistics("monthly");

                // setEventData({});
                // setDynamicModal(false);
                // setDynamicModalName("");
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
              setCalendarReload(true);
            })
            //Then with the error genereted...
            .catch((error) => {
              setCalendarReload(true);
              console.error("Error:", error);
            });
        }
      });
  };

  const scheduleEvent = (event) => {
    if (event) {
      setTimeZone(event.time_zone1);
      setMainTimeZone(event.time_zone);
      setEventData(event);
      setEventModal(true);
    }
  }

  const handleEventSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = eventData.client_id;
    values.calendar_id = eventData.calendar_id;
    values.type = "meeting";
    
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setEventModal(false);
          // getTaskStatistics("monthly");
          setCalendarReload(true);
          setEventData({});
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <Helmet>
        <title></title>
      </Helmet>
      <Row className="DashboardPage">
          <Col xl="12" lg="12" sm="12" xs="12" className="display-flex space-between mb-2">
            <Card className="VariousCalls TotalRecord MyScheduleMain TaskManager mb-5 mt-1">
              <CardBody style={{minHeight:'280px',}}>
                <div className="border-three">
                <Row className="mb-3">
                  <Col md="10">
                  </Col>
                   <Col md="2">
                    <div className="MonthlyDropDown p-tracker pull-right">
                      <select value={"All Campaigns"}  className="form-control">
                          <option value={"All Campaigns"}>All Campaigns</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                  <Row>
                    <Col xl="4" lg="4" xs="4">
                      <Card onClick={() => getTaskStatistics("today")} className="Message p-3 mb-3">
                        <div className="IconWithCount">
                          <div className="dot code-blue"></div>
                          <span>Talking</span>
                        </div>
                        <div className="NumberFunc">0</div>
                      </Card>
                    </Col>
                    <Col xl="4" lg="4" xs="4">
                      <Card onClick={() => getTaskStatistics("weekly")} className="Email p-3 mb-3">
                        <div className="IconWithCount">
                          <div className="dot code-yellow"></div>
                          <span>Waiting</span>
                        </div>
                        <div className="NumberFunc">0</div>
                      </Card>
                    </Col>
                    <Col xl="4" lg="4" xs="4">
                      <Card onClick={() => getTaskStatistics("monthly")} className="ReceivedCalls p-3 m-0">
                        <div className="IconWithCount"><div className="dot code-dark"></div>
                          <span>Pause</span>
                        </div>
                        <div className="NumberFunc">0</div>
                      </Card>
                    </Col>
                    <Col xl="4" lg="4" xs="4">
                      <Card onClick={() => getTaskStatistics("unassigned")} className="MissedCalls p-3 m-0">
                        <div className="IconWithCount">
                          <div className="dot code-green"></div>
                          <span>Connected</span>
                        </div>
                        <div className="NumberFunc">{task_record.unassigned}</div>
                      </Card>
                    </Col>
                    <Col xl="4" lg="4" xs="4">
                      <Card onClick={() => getTaskStatistics("monthly")} className="ReceivedCalls p-3 m-0">
                        <div className="IconWithCount"><div className="dot code-red"></div>
                          <span>Offline</span>
                        </div>
                        <div className="NumberFunc">{task_record.monthly}</div>
                      </Card>
                    </Col>
                    <Col xl="4" lg="4" xs="4">
                      <Card onClick={() => getTaskStatistics("unassigned")} className="MissedCalls p-3 m-0">
                        <div className="IconWithCount">
                          <div className="dot code-green"></div>
                          <span>Sales</span>
                        </div>
                        <div className="NumberFunc">{task_record.unassigned}</div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
      </Row>
      <Row className="DashboardPage">
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card>
            <CardBody style={{minHeight: '220px', maxHeight: '220px'}}>
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Conversion</h4></div>
              <div className="ProgressDashboard OutboundGoals" style={{ width: "100%" }}>
                <div className="BarProgress">
                  <i className="mdi mdi-currency-usd"></i><div className="ProgressLabel"><label>Sales Ratio</label><Progress color="primary" title={50} value={50}>
                    <div className="TotalProgress">50%</div>
                  </Progress></div>
                </div>
              </div>
              <div className="ProgressDashboard OutboundGoals mt-2">
                <div className="BarProgress">
                  <i className="ion ion-md-thumbs-up"></i><div className="ProgressLabel"><label>Response Rate</label><Progress color="primary" title={100} value={100}>
                     <div className="TotalProgress">100%</div>
                  </Progress>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card className="VariousCalls BusinessInbox">
            <CardBody>
              <div className="DashboardHeadingSection"><h4 className="card-title">Leads</h4></div>
              <div className="border-three">
                <Row>
                  <Col xl="6" lg="6" xs="6"><Card
                    className="Message p-3 mb-3">Dialable Leads
                    <div className="NumberFunc">8000</div>
                  </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card className="Email p-3 mb-3">Leads in buffer
                      <div className="NumberFunc">100
                      </div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card
                      className="ReceivedCalls p-3 mb-2">
                      Total Missed calls
                      <div className="NumberFunc" >10</div>
                    </Card>
                  </Col>
                 <Col xl="6" lg="6" xs="6">
                    <Card
                      className="ReceivedCalls p-3 mb-2">
                      Dropped Ratio
                      <div className="NumberFunc" >30%</div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
         <Col xl="4" lg="4" sm="6" xs="12">
          <Card className="VariousCalls BusinessInbox">
            <CardBody>
              <div className="DashboardHeadingSection"><h4 className="card-title">Calls</h4></div>
              <div className="border-three">
                <Row>
                  <Col xl="6" lg="6" xs="6"><Card
                    className="Message p-3 mb-3">Total Calls Today
                    <div className="NumberFunc">0</div>
                  </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card className="Email p-3 mb-3">Avg Call Time
                      <div className="NumberFunc">0
                      </div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card
                      className="ReceivedCalls p-3 mb-2">
                      Total Calls Handled
                      <div className="NumberFunc" >0</div>
                    </Card>
                  </Col>
                 <Col xl="6" lg="6" xs="6">
                    <Card
                      className="ReceivedCalls p-3 mb-2">
                      Calls Ringing
                      <div className="NumberFunc" >0</div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      
      <Row>
        <Col xl="12" lg="12">
          <Card style={{ height: '81vh' }}>
            <CardBody>
              <Row>
                <Col md="6">
                <h4 className="card-title mb-4">Live Agent Monitoring</h4>
                </Col>
                 <Col md="6">
                  <div className="MonthlyDropDown p-tracker pull-right">
                    <select value={"All Agents"}  className="form-control ml-3">
                        <option value={"All Agents"}>All Agents</option>
                    </select>
                    <select value={"All Campaigns"}  className="form-control">
                        <option value={"All Campaigns"}>All Campaigns</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive" style={{ height: '90%' }}>
                <Table className="align-middle table-centered table-vertical table-nowrap">
                  <thead>
                    <tr>
                      <th>Agents</th>
                      <th>Status</th>
                      <th>Campaigns</th>
                      <th>Calls</th>
                      <th>Sales</th>
                      <th>Pause Time</th>
                      <th>Wait Time</th>
                      <th>Talk Time</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td colSpan={8}  className="text-center"> No record found</td>
                      </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>


      <Modal
        isOpen={taskModal}
        fade={true}
        size="md"
        toggle={() => {
          setTaskModal(false);
          setTaskData({});
        }}
      >
        <AvForm
          onValidSubmit={handleTaskSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Add Task </h2>
              <button
                type="button"
                onClick={() => {
                  setTaskModal(false);
                  setTaskData({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3 row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="event_title"
                    label="Task Title"
                    className="form-control"
                    required
                    type="text"
                    value={taskData.event_title}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_date"
                    label="Select Date"
                    className="form-control"
                    required
                    type="date"
                    value={taskData.server_date}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_time"
                    label="Select Time"
                    className="form-control"
                    required
                    type="time"
                    value={taskData.server_time}
                  />
                </div>
                <Col md={6}>
                  <AvGroup>
                    <label> MainTime Zone </label>
                    <div className="MainTZone MainTime One">
                      <Select
                        value={maintimezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={maintimezones}
                        classNamePrefix="select2-selection"
                        className={`${maintimezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone"
                        onChange={(e) => {
                          settimezone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone_1"
                      value={maintimezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <label> Time Zone </label>
                    <div className="MainTZone MainTime One Two">
                      <Select
                        value={timezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={timeZone_data}
                        classNamePrefix="select2-selection"
                        className={`${timezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone1"
                        onChange={(e) => {
                          setTimeZone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone"
                      value={timezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={12}>
                  <label className="control-label">Client</label>
                  {
                    taskData.meeting_id ? (
                      <select disabled className="form-control">
                        <option value={taskData.client_id}>{taskData.client_name}</option>

                      </select>

                    ) : (
                      <div className="Task One">
                        <Select
                          getOptionLabel={option => option.client_id + ' # ' + option.first_name + ' ' + option.last_name + ((option.email != '') ? ' - ' + option.email : ' - ' + option.country_code + option.mobile)}
                          getOptionValue={(option) => option.client_id}
                          isMulti={false}
                          value={clients.filter(
                            (row) => row.client_id === client_id
                          )}
                          options={clients}
                          classNamePrefix="select2-selection"
                          onInputChange={handleInputChange}
                          isDisabled={taskData.meeting_id ? true : false}
                          onChange={(e) => {
                            setClientId(e.client_id);
                            setClient(e);
                          }}
                        />

                      </div>
                    )
                  }
                  <AvField
                    required
                    type="hidden"
                    name="client_id"
                    value={client_id}
                  />
                </Col>
                <Col md={12}>
                  <div className="MainTZone Task Two">
                    <AvField
                      className="select form-control"
                      type="select"
                      name="assign_to"
                      label="Assign To"
                      value={taskData.assign_to}
                    >
                      <option value={""}> Un Assign </option>
                      {client.client_id && (
                        <optgroup label="This Client/Lead">
                          <option key={1} value={client.client_id}>{client.first_name + client.last_name + " - " + (client.email ? client.email : client.mobile)}</option>
                        </optgroup>
                      )}
                      <optgroup label="Coach">
                        <option key={1} value={coachUser.user_id}>{coachUser.name + " - " + coachUser.email}</option>
                      </optgroup>
                      <optgroup label="Coach Partner">
                        {coachPartners.map((row, i) => {
                          return (
                            <option key={i} value={row.user_id}>
                              {row.name + " - " + row.email}
                            </option>
                          );
                        })}
                      </optgroup>
                    </AvField>
                  </div>
                </Col>
                {
                  taskData.meeting_id && (
                    <AvField
                      type="hidden"
                      name="meeting_id"
                      value={taskData.meeting_id}
                    />
                  )
                }
              </div>
            </ModalBody>
            <ModalFooter>
              {
                taskData.meeting_id && (
                  <Button color="warning" onClick={() => { deleteTaskAndEvents(taskData.meeting_id) }}>
                    Delete this task
                  </Button>
                )
              }
              <Button color="info" type="submit">
                Save
              </Button>
              <Button color="danger" onClick={() => { setTaskModal(false); setTaskData({}); }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal >

      <Modal
        isOpen={eventModal}
        fade={true}
        size="md"
        toggle={() => {
          setEventModal(false);
          setEventData({});
        }}
      >
        <AvForm
          onValidSubmit={handleEventSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Schedule an event </h2>
              <button
                type="button"
                onClick={() => {
                  setEventModal(false);
                  setEventData({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3 row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="event_title"
                    label="Task Title"
                    className="form-control"
                    required
                    type="text"
                    value={eventData.event_title}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_date"
                    label="Select Date"
                    className="form-control"
                    required
                    type="date"
                    value={eventData.server_date}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_time"
                    label="Select Time"
                    className="form-control"
                    required
                    type="time"
                    value={eventData.from_time}
                  />
                </div>
                <Col md={6}>
                  <AvGroup>
                    <label> MainTime Zone </label>
                    <div className="MainTZone MainTime One">
                      <Select
                        value={maintimezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={maintimezones}
                        classNamePrefix="select2-selection"
                        className={`${maintimezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone"
                        onChange={(e) => {
                          settimezone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone_1"
                      value={maintimezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <label> Time Zone </label>
                    <div className="MainTZone MainTime One Two">
                      <Select
                        value={timezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={timeZone_data}
                        classNamePrefix="select2-selection"
                        className={`${timezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone1"
                        onChange={(e) => {
                          setTimeZone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone"
                      value={timezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                {
                  eventData.meeting_id && (
                    <AvField
                      type="hidden"
                      name="meeting_id"
                      value={eventData.meeting_id}
                    />
                  )
                }
              </div>
            </ModalBody>
            <ModalFooter>
              {
                eventData.meeting_id && (
                  <Button color="warning" onClick={() => { deleteTaskAndEvents(eventData.meeting_id) }}>
                    Delete this schedule
                  </Button>
                )
              }
              <Button color="info" type="submit">
                Save
              </Button>
              <Button color="danger" onClick={() => { setEventModal(false); setEventData({}); }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
      <Modal
        isOpen={goalsModal}
        fade={true}
        size="md"
        toggle={() => {
          setGoalsModal(false);
        }}
      >
        <AvForm
          onValidSubmit={handleGoalsSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Set Goals </h2>
              <button
                type="button"
                onClick={() => {
                  setGoalsModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3 row">
                {goalsArray.length > 0 && (
                  goalsArray.map((row, i) => {
                    return (
                      <div className="col-md-12 mb-3">
                        <AvField
                          name={row[0]}
                          label={row[1]}
                          className="form-control"
                          required
                          type="text"
                          value={row[2]}
                        />
                      </div>
                    )
                  })
                )
                }
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Save
              </Button>
              <Button type="button" color="danger" onClick={() => setGoalsModal(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment >
  );
};

export default Coach;
