import React, { Component, Fragment } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import CreditsSidebar from "./credits-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import Helmet from "react-helmet";
import { bcrypt, isAllowed, uInfo, uRole, uToken } from "../../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Badge from "react-bootstrap/Badge";
export default class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "id",
        key: "id",
        sortable: false,
      },
      {
        text: "Name",
        key: "name",
        sortable: false,
      },
      {
        text: "Email",
        key: "email",
        sortable: false,
      },
      {
        text: "Amount ($)",
        key: "amount",
        sortable: false,
      },
      {
        text: "Action",
        key: "other_settings",
        sortable: false,
      },
      {
        text: "Dated",
        key: "created_at",
        sortable: false,
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      sort: { column: "total_lead", order: "desc" },
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      selectadminmodal: false,
      admincreditrecords: [],
      id: "",
      available_credit: "",
      minusmodal: false,
      addmodal: false,
      propsUpdate: false,
      records: [],
      total_pages: 0,
      record: {},
      admin: "",
      admins: [],
      path: props.match.params.path,
      page_name: "Add Free Credit - Coaches",
      add_credit: 0,
      sub_credit: 0
    };

    this.handleSubmitaddCredits = this.handleSubmitaddCredits.bind(this);
  }

  getcredits(datarecord) {
    this.setState({
      admin: datarecord,
    });

    fetch(`${ApiUrl}` + "get/admin/credits", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: datarecord.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (data.data.records) {
            this.setState({
              //records: data.data.records,
              admincreditrecords: data.data.records.available_credit,
              id: datarecord.id,
            });
            this.getcreditlog(datarecord.id);
          }
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getcreditlog(id) {
    fetch(`${ApiUrl}` + "get/credit/log/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (data.data.records) {
            this.setState({
              records: data.data.records,
              total_pages: data.data.total,

            });
          }
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  closeselectadminmodal() {
    this.setState({
      selectadminmodal: !this.state.selectadminmodal,
    });
  }
  openaddmodal(available_credit, id) {
    if (this.state.id == "") {
      this.setState({
        selectadminmodal: !this.state.selectadminmodal,
      });
    } else {
      this.setState({
        id: id,
        available_credit: available_credit,
        addmodal: !this.state.addmodal,
      });
    }
  }
  openminusmodal(available_credit, id) {
    if (this.state.id == "") {
      this.setState({
        selectadminmodal: !this.state.selectadminmodal,
      });
    } else {
      this.setState({
        id: id,
        available_credit: available_credit,
        minusmodal: !this.state.minusmodal,
      });
    }
  }
  componentDidMount() {
    this.getData();
    this.getcreditlog("0");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.path != this.props.match.params.path) {
      this.setState(
        {
          path: this.props.match.params.path,
          page_name: "Add Free Credit - Coaches",
        },
        () => this.getData()
      );
    }
  }

  getData = (queryString = this.state.id ? this.state.id : "Null") => {
    fetch(`${ApiUrl}` + "get/admin/accounts" + "/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ type: this.state.path }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            // total_pages: data.data.total,
            //records: data.data.records,
            admins: data.data.records,
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
          // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");

    this.getcreditlog(`${this.state.id != 0 ? this.state.id : '0'}?` + queryString);
  };

  handleSubmitaddCredits = async (event, values) => {
    event.preventDefault();

    fetch(`${ApiUrl}` + "update/usercredits", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        add_credit: event.target.add_credit.value,
        id: this.state.id,
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
         

          this.setState({
            addmodal: false,
            add_credit: 0
          });

          this.getcreditlog(this.state.id);
          this.props.history.push("/credits");
          // toast.configure();
                 // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitminusCredits = async (event, values) => {
    event.preventDefault();
    fetch(`${ApiUrl}` + "deduct/usercredits", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        deduct_credit: event.target.deduct_credit.value,
        id: this.state.id,
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          

          this.setState({
            minusmodal: false,
            sub_credit: 0
          });

          this.getcreditlog(this.state.id);
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col lg="2" className="pl-0">
            <CreditsSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={4}>
                <AvForm
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  {/* <AvField
                                    className="select form-control"
                                    type="select"
                                    name="admin"
                                    label="Coaches"
                                    onChange={(e) => {
                                      this.getcredits(e.target.value);
                                    }}
                                  >
                                    <option value=""> Select Admin </option>
                                    {this.state.admins.map((admin) => {
                                      return <option value={admin.id}>{admin.name}</option>;
                                    })}
                                  </AvField> */}
                  <AvGroup>
                    <label className="m-0"> Coaches </label>
                    <div className="CoachesDrop">
                      <div className="DropDownCoh">
                        <Select
                          value={this.state.admin}
                          getOptionLabel={(option) => option.name + ' - ' + option.email}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={this.state.admins}
                          classNamePrefix="select2-selection"
                          className={"is-touched is-dirty av-valid"}
                          name="admin"
                          onChange={(e) => {
                            this.getcredits(e);
                          }}
                        />
                      </div>
                    </div>
                  </AvGroup>
                </AvForm>
              </Col>
              <Col
                sm={2}
                // className="mt-4"
                style={{ marginTop: "30px" }}
              >
                <Tooltip title="Add Free Credits" placement="top">
                  <Link
                    color="info"
                    className="btn btn-primary btn-sm mb-1 mr-5"
                    onClick={() => {
                      this.openaddmodal(
                        this.state.admincreditrecords,
                        this.state.id
                      );
                    }}
                  >
                    +
                  </Link>
                </Tooltip>
                <Tooltip title="Deduct Free Credits" placement="top">
                  <Link
                    color="info"
                    className="btn btn-danger btn-sm mb-1 mr-5"
                    onClick={() => {
                      this.openminusmodal(
                        this.state.admincreditrecords,
                        this.state.id
                      );
                    }}
                  >
                    -
                  </Link>
                </Tooltip>
              </Col>
            </Row>
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      key={this.state.record.sr}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />
                    {/* <table className="table table-striped table-bordered">
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Amount (¢)</th>
                                          <th>Consumed Credit</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.records && (
                                          <tr>
                                            <td>{this.state.records.name}</td>
                                            <td>{this.state.records.email}</td>
                                            <td>{this.state.records.available_credit}</td>
                                            <td>{this.state.records.consume_credit}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.addmodal}
              fade={false}
              toggle={() => this.toggle()}
            >
              <ModalBody>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <Form
                        onSubmit={this.handleSubmitaddCredits}
                        // ref={(c) => (this.form = c)}
                        ref="Form"
                        className="needs-validation"
                      >
                        <ModalBody>
                          <fieldset>
                            <div className="row">
                              <h4> Add Free Amount(¢) </h4>
                              <div className="col-lg-5">
                                <h6>
                                  Available Amount {this.state.available_credit}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  {/* <AvField
                                              name="add_credit"
                                              label="Add Amount"
                                              type="number"
                                              className="form-control"
                                              placeholder="Add Amount"
                                              required
                                            /> */}
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                      (¢)
                                    </InputGroup.Text>
                                    <FormControl
                                      name="add_credit"
                                      label="Add Amount"
                                      type="number"
                                      value={this.state.add_credit}
                                      className="form-control"
                                      placeholder="Add Amount"
                                      required
                                      onChange={(e) => this.setState({ add_credit: e.target.value.replace(/\D/g, '') })}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
                            </div>

                          </fieldset>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            type="button"
                            color="secondary"
                            onClick={() => this.openaddmodal()}
                          >
                            Cancel
                          </Button>
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Submit
                          </button>
                        </ModalFooter>
                      </Form>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.minusmodal}
              fade={false}
              toggle={() => this.toggle()}
            >
              <ModalBody>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <Form
                        onSubmit={this.handleSubmitminusCredits}
                        // ref={(c) => (this.form = c)}
                        ref="Form"
                        className="needs-validation"
                      >
                        <ModalBody>
                          <div className="row">
                            <h4> Remove Free Amount(¢) </h4>
                            <div className="col-lg-5">
                              <h6>
                                Available Amount {this.state.available_credit}
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                {/* <AvField
                                                  name="deduct_credit"
                                                  label="Deduct Amount"
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Deduct Amount"
                                                  required
                                                /> */}
                                <InputGroup className="mb-3">
                                  <InputGroup.Text id="basic-addon3">
                                    (¢)
                                  </InputGroup.Text>
                                  <FormControl
                                    name="deduct_credit"
                                    label="Deduct Amount"
                                    type="number"
                                    className="form-control"
                                    placeholder="Deduct Amount"
                                    required
                                    value={this.state.sub_credit}
                                    onChange={(e) => this.setState({ sub_credit: e.target.value.replace(/\D/g, '') })}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            type="button"
                            color="secondary"
                            onClick={() => this.openminusmodal()}
                          >
                            Cancel
                          </Button>
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Submit
                          </button>
                        </ModalFooter>
                      </Form>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.selectadminmodal}
              fade={false}
              toggle={() => this.toggle()}
            >
              <ModalBody>
                <div className="warning-box text-center">
                  <span className="warning">
                    <i className="mdi mdi-alert-circle"> </i>
                  </span>
                  <h3> Please Select User! </h3>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => this.closeselectadminmodal()}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
