import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import "react-toastify/dist/ReactToastify.css";
import {
  bcrypt,
  isAllowed,
  uInfo,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from 'sweetalert';
import { Link } from "react-router-dom";

export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      total_pages: 0,
      tab: "4",
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "Agent Scripts" : "Agent Scripts",
    };
    this.columns = [
      {
        text: "Name",
        key: "name",
      },
      // {
      //   text: "Type",
      //   key: "type",
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         {record.type == 1 && <Badge bg="success">OutBound</Badge>}
      //         {record.type == 2 && <Badge bg="danger">Inbound</Badge>}
      //       </Fragment>
      //     );
      //   },
      // },
      {
        text: "Date Created",
        key: "created_at",
      },

      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                type="button"
                color="info"
                className="btn btn-primary btn-sm mb-1 mr-5"
                onClick={() => this.props.history.push("/agent/script/edit/" + bcrypt(record.id))}
              >
                Edit
              </button>
              {/*<button
                className="btn btn-danger btn-sm mb-1"
                onClick={this.deleteRecord.bind(this, record, index)}
              >
                Delete
              </button> */}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
  }
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };
  toggle(index) {
     this.props.history.push("/agent/script/list");
    
  }
  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "") => {
    fetch(`${ApiUrl}` + "get/templates/scripts" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/scripttemplate", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              {isAllowed(uInfo(), ["can_create"]) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-info"
                  to={"/agent/script/add/"}
                // onClick={() => {
                //   this.setState({
                //     modal: !this.state.modal
                //   })
                // }}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create
                </Link>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <TabContent activeTab={this.state.tab}>
            <TabPane tabId="4">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <ReactDatatable
                        config={this.config}
                        records={this.state.records}
                        columns={this.columns}
                        dynamic={true}
                        total_record={this.state.total_pages}
                        onChange={this.tableChangeHandler}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Row>
      </React.Fragment>
    );
  }
}
