import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader

} from "reactstrap";
import Select from "react-select";

import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
const UiDropdown = (props) => {
  useEffect(() => { });
  const [subscriptionid, setsubscriptionid] = useState(props.props);
  const [userid, setuserid] = useState(props.userid);
  const [record, setRecord] = useState(props.record);
  const [btnInfo1, setBtnInfo1] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deletealert, setdeletealert] = useState(false);
  const [status, setstatus] = useState(props.status);
  const [confirmmodal, setconfirmmodal] = useState(false);
  const [modal, setModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);

  const [coachList, setCoachList] = useState([]);


  const [toggleSwitch, settoggleSwitch] = useState(false);
  const [toggleSwitch1, settoggleSwitch1] = useState(false);

  const history = useHistory();
  const cancelsubscription = () => {
    fetch(`${ApiUrl}` + "cancel/user/subscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: props.userid }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          props.trigger_get_user();
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };
  const canceleuser = () => {
    swal({
      title: "Are you sure?",
      text: "You want to cancel this user. Doing so will cancel all of this user's coaching partners and clients. In addition, recurring subscriptions and call API accounts will be permanently removed.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((isCancel) => {
        if (isCancel) {
          swal('"Enter keyword "CANCEL" to cancel this user', {
            content: "input",
            buttons: true,
            dangerMode: true,
          })
            .then((value) => {
              if (value.toLowerCase() == 'cancel') {
                cancelsubscription();
              } else {
                //swal("Sorry you entered keyword is not valid.", "success");
                swal("Sorry you entered keyword is not valid.", {
                  icon: "error",
                });
              }
            });
        }
      })

  }


  const loginAsSubCoach = () => {
    swal({
      title: "Are you sure?",
      text: "You want to login this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isDelete) => {
      if (isDelete) {
        fetch(`${ApiUrl}` + "login/sub/coach", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ user_id: props.userid, to_user_id: uInfo().staff_user_id > 0 ? uInfo().staff_user_id : uInfo().user_id }),
        })
          .then((response) => response.json())
          .then((response) => {

            if (response.status == true) {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              props.loginAsSubCoach(response.data.user, response.data.users);
            } else {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  const deleteuser = () => {
    swal({
      title: "Are you sure?",
      text: "You want to want to permanently delete this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isDelete) => {
      if (isDelete) {
        fetch(`${ApiUrl}` + "delete/user", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ id: props.userid }),
        })
          .then((response) => response.json())
          .then((response) => {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
            props.trigger_get_user();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();

    submitData.append('user_id', record.id);
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }


    fetch(`${ApiUrl}` + "manual/verfication", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          props.trigger_get_user();
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getCoachList = () => {
    fetch(`${ApiUrl}` + "get/coaches", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setCoachList(response.data.records);
          setCloneModal(true);
        } else {
          toast.error(response.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={btnInfo1}
        toggle={() => setBtnInfo1(!btnInfo1)}
        className="btn-group me-1"
      >
        <Dropdown.Toggle className="btn btn-info dropdown-toggle ti-settings" variant="success" id="dropdown-basic">
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {status != "Canceled" ? (
            <>
              <Dropdown.Item>
                <li>
                  <a
                    onClick={() => {
                      history.push("coach/edit/" + bcrypt(userid));
                    }}
                    className="btn btn-default"
                  >
                    <i className="fas fa-pencil-alt"> </i> Edit
                  </a>
                </li>
              </Dropdown.Item>
              {record.role_id > 0 && (
                <Dropdown.Item>

                  <li>
                    <a
                      onClick={() => {
                        history.push('/users/permissionLibrary/edit/' + bcrypt(record.role_id))
                      }}
                      className="btn btn-default"
                    >
                      <i className="fas fa-pencil-alt"> </i>  Edit Permission Library
                    </a>
                  </li>
                </Dropdown.Item>
              )}
              {record.is_approved != "Yes" && (
                <Dropdown.Item>
                  <li>
                    <a
                      onClick={() => {
                        props.changeApprovalUser(record)
                      }}
                      className="btn btn-default"
                    >
                      <i className="fas fa-check-circle"> </i>  Approve Coach
                    </a>
                  </li>
                </Dropdown.Item>
              )}

              <Dropdown.Item>
                <li>
                  <a
                    onClick={() => {
                      setModal(true);
                      settoggleSwitch(record.phone_verified == 'Y' ? true : false);
                      settoggleSwitch1(record.email_verified == 'Y' ? true : false);
                    }}
                    className="btn btn-default"
                  >
                    <i class="fas fa-user-check"></i>  Manual Verification
                  </a>
                </li>
              </Dropdown.Item>
              {/* 
              <Dropdown.Item>
                <li>
                  <a
                    onClick={() => {
                      getCoachList();
                    }}
                    className="btn btn-default"
                  >
                    <i class="mdi mdi-content-copy"></i>  Clone Data
                  </a>
                </li>
              </Dropdown.Item> */}

              <Dropdown.Item>
                <li>
                  <a
                    onClick={() => {
                      loginAsSubCoach()
                    }}
                    className="btn btn-default"
                  >
                    <i className="fas fa-sign-out-alt"> </i> Login
                  </a>
                </li>
              </Dropdown.Item>
              <Dropdown.Item>
                <li>
                  <a
                    onClick={() => {
                      canceleuser()
                    }}
                    className="btn btn-default"
                  >
                    <i className="fas fa-times"> </i> Cancel
                  </a>
                </li>
              </Dropdown.Item>
            </>
          ) : (
            <Dropdown.Item>
              <li>
                <a
                  onClick={() => {
                    deleteuser();
                  }}
                  className="btn btn-default"
                >
                  <i className="fas fa-trash-alt"> </i> Delete
                </a>
              </li>
            </Dropdown.Item>
          )}

        </Dropdown.Menu>

      </Dropdown>
      <Modal
        isOpen={modal}
        fade={true}
        size="md"
        toggle={() => {
          setModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Manual Verification
          </h5>
          <div className="pull-right">
            <button onClick={() => setModal(false)} className="btn btn-sm" ><i className="fas fa-times"></i></button>
          </div>
        </div>

        <form onSubmit={handleSubmit} method="post"
          encType="multipart/form-data" className="form-horizontal" id="selection-myform">
          <ModalBody>

            <div className="row">
              <div className="col-lg-12">
                <div className="form-check  form-switch form-switch-md mb-3">
                  <label className="form-check-label">Phone Verified</label>
                  <input
                    type="checkbox"
                    key={record.user_id}
                    className="form-check-input"
                    name="phone_verified"
                    defaultChecked={toggleSwitch}
                    onClick={e => {
                      settoggleSwitch(!toggleSwitch)
                    }}
                  />
                </div>

                <div className="form-check  form-switch form-switch-md mb-3">
                  <label className="form-check-label">Email Verified</label>
                  <input
                    type="checkbox"
                    key={record.user_id}
                    className="form-check-input"
                    name="email_verified"
                    defaultChecked={toggleSwitch1}
                    onClick={e => {
                      settoggleSwitch1(!toggleSwitch1)
                    }}
                  />
                </div>
              </div>
            </div>

          </ModalBody>
          <ModalFooter>

            <button type="submut" className="btn btn-success ml-5">Update Verification</button>
          </ModalFooter>
        </form>
      </Modal>


      <Modal
        isOpen={cloneModal}
        fade={true}
        size="md"
        toggle={() => {
          setCloneModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Clone Data
          </h5>
          <div className="pull-right">
            <button onClick={() => setCloneModal(false)} className="btn btn-sm" ><i className="fas fa-times"></i></button>
          </div>
        </div>

        <form onSubmit={handleSubmit} method="post"
          encType="multipart/form-data" className="form-horizontal" id="selection-myform">
          <ModalBody>
            <p>Select the coach where you want to clone <b>{props.record.name}</b> data.</p>
            <div className="form-group mt-3">
              <label htmlFor="example-text-input" className="form-label">Coach List</label>
              <Select
                isClearable
                getOptionLabel={option => option.name}
                getOptionValue={option => option.user_id}
                isMulti={false}
                menuIsOpen={true}
                placeholder={'Select Coach where you want to clone'}
                options={coachList}
                classNamePrefix="select2-selection"
                name="to_user_id"
              />
            </div>
          </ModalBody>
          <ModalFooter>

            <button type="submut" className="btn btn-success ml-5">Clone</button>
          </ModalFooter>
        </form>
      </Modal>

    </React.Fragment>
  );
};

export default UiDropdown;
