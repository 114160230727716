import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import CreditsSidebar from "./credits-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import { AvForm, AvField } from "availity-reactstrap-validation";
import Helmet from "react-helmet";
import { bcrypt,   dcrypt,isAllowed, uInfo, uRole, uToken } from "../../../../useToken";
import { ApiUrl, ProductName } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";


export default class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "Sr#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Name",
        key: "name",
        sortable: false,
      },
      {
        text: "Amount (¢)",
        key: "amount",
        sortable: false,
      },
      {
        text: "Type",
        key: "other_settings",
        sortable: false,
      },
      
      {
        text: "Date",
        key: "created_at",
        sortable: false,
      },
  

    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: false,
      show_pagination: true,
      sort: { column: "total_lead", order: "desc" },
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.state = {
      id: this.props.match.params.id
     ? dcrypt(this.props.match.params.id)
     : null,
      available_credit: "",
      minusmodal: false,
      addmodal: false,
      propsUpdate: false,
      records: [],
      total_pages: 0,
      record: {},
      path: props.match.params.path,
      page_name: "WL Admin Credit log",
    };

    
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.path != this.props.match.params.path) {
      this.setState(
        {
          path: this.props.match.params.path,
          page_name: "WL Admin Credit Log",
        },
        () => this.getData()
      );
    }
  }

  getData = (queryString =this.state.id ) => {
    fetch(`${ApiUrl}` + "get/credit/log" +"/" +queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ type: this.state.path }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
            
          this.setState({
            total_pages: data.data.total,
            records: data.data.records,
          });
          
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
          // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString);
  };




  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>
     <Row>
       <Col lg="2" className="pl-0">

       <CreditsSidebar menuOpen={false} props={this.props} />

       </Col>
       <Col lg="10">
       <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back{" "}
              </Button>
            </div>{" "}
          </Col>{" "}
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      
       </Col>
     </Row>
      </React.Fragment>
    );
  }
}
