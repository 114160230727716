import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
  convertPhoneFormat,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../common/data/countries";
import CampaignNav from "./nav";
import { Tab, Tabs } from "react-bootstrap";
import { Asia, TimezoneMain, America, Australia, Europe, Indian } from "../../common/data/timezones";

export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_name: this.props.match.params.id
        ? "Edit Campaign"
        : "Create Campaign",
      from: this.props.from ? this.props.from : "",
      record: {
        'from_email': uInfo().email,
      },
      groups: [],
      permissions: [],
      tags: [],
      teams: [],
      scripts: [],
      holidays: {},
      managers: [],
      group_id: [],
      team_id: [],
      tag_id: [],
      emails: [],
      phoneNumbers: [],
      permission: [],
      country_code: [],
      CustomField: [],
      customFieldData: {},
      tab: "1",
      group_status: 'No',
      incoming_SMS_Notification: false,
      incomingNumber: '',
      voicemail_Votification_SMS: false,
      voicemail_Notification_EMAIL: false,
      voicemail_SMS: '',
      voicemail_EMAIL: '',
      group_status_id: '',
      main: 'Asia',
      Timezone: TimezoneMain,
      zones: Asia,
      zone: 'Asia/Aden'
    };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getFormData();
  }


  getFormData = () => {
    fetch(`${ApiUrl}` + "get/campaign/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            scripts: response.data.scripts,
            teams: response.data.teams,
            groups: response.data.groups,
            tags: response.data.tags,
            holidays: response.data.holidays,
            emails: response.data.emails,
            phoneNumbers: response.data.phoneNumbers,
            permissions: response.data.coachPartners,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/campaign", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        coach_id: this.state.coach_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedTeam: response.data.record.team_ids,
            team_id: response.data.record.team_id,
            selectedTags: response.data.record.tag_ids,
            selectedGroup: response.data.record.group_ids,
            group_id: response.data.record.group_id,
            tag_id: response.data.record.tag_id,
            selectedPermission: response.data.record.permissions,
            permission: response.data.record.permission,
            group_status: response.data.record.group_status,
            group_status_id: response.data.record.group_status_id,
            main: response.data.record.main_zone,
            zone: response.data.record.time_zone,
            incoming_SMS_Notification: response.data.record.notify_incoming_sms == 'Yes' ? true : false,
            voicemail_Votification_SMS: response.data.record.notify_sms == 'Yes' ? true : false,
            voicemail_Notification_EMAIL: response.data.record.notify_email == 'Yes' ? true : false,
            voicemail_SMS: response.data.record.sms_number,
            voicemail_EMAIL: response.data.record.email_address,
            incomingNumber: response.data.record.notify_incoming_number,
          });







          this.changeMainZone(response.data.record.main_zone);
          this.changeTimeZone(response.data.record.time_zone);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitOtherSetting = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.campaign_id
        ? this.state.record.campaign_id
        : -1;
    }

    values.notify_email = values.notify_email ? 'Yes' : 'No';
    values.notify_sms = values.notify_sms ? 'Yes' : 'No';
    values.notify_incoming_sms = values.notify_incoming_sms ? 'Yes' : 'No';
    fetch(`${ApiUrl}` + "add/campaign/other/setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleSubmitConstraint = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.campaign_id
        ? this.state.record.campaign_id
        : -1;
    }

    if (values.holidays && values.skip_weak_days) {
      values.holidays = values.holidays.toString();
      values.skip_weak_days = values.skip_weak_days.toString();
    }

    fetch(`${ApiUrl}` + "update/campaign/constraint", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.campaign_id
        ? this.state.record.campaign_id
        : -1;
    }

    if (values.holidays && values.skip_weak_days) {
      values.holidays = values.holidays.toString();
      values.skip_weak_days = values.skip_weak_days.toString();
    }

    fetch(`${ApiUrl}` + "add/campaign", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          setTimeout(
              () => this.props.history.push('/campaign/list'),
              1000
          );
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleTag = (selectedTags) => {
    var filter = [];
    if (selectedTags) {
      selectedTags.map((fil) => {
        filter.push(fil.group_id);
      });
    }

    this.setState({
      tag_id: filter.toString(),
      selectedTags: selectedTags,
    });
  };

  handleTeam = (selectedTeam) => {
        var filter = [];
        if (selectedTeam) {
            selectedTeam.map(fil => {
                filter.push(fil.id);
            })
        }

        this.setState({
            team_id: filter.toString(),
            selectedTeam: selectedTeam
        })

    }
  handleGroup = (selectedGroup) => {
    var filter = [];
    if (selectedGroup) {
      selectedGroup.map((fil) => {
        filter.push(fil.group_id);
      });
    }

    this.setState({
      group_id: filter.toString(),
      selectedGroup: selectedGroup,
    });
  };

  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState({
      permission: filter.toString(),
      selectedPermission: selectedPermission,
    });
  };

  toggle = (index) => {
    if (this.state.tab !== index) {
      if (index == "7") {
        this.props.history.push("/campaign/automation/list/" + bcrypt(this.state.id));
      } else {
        this.setState({
          tab: index,
        });
      }
    }
  };

  changeTimeZone = (zone) => {
    this.setState({
      zone: zone
    })
    // setZone(zone);
    // setRecord(currValue => ({
    //     ...currValue,
    //     time_zone: zone
    // }))
  }

  changeMainZone = (zone) => {
    if (zone == 'Asia') {
      this.setState({
        zones: Asia,
        zone: Asia[0].id
      })
    } else if (zone == 'America') {
      this.setState({
        zones: America,
        zone: America[0].id
      })
    } else if (zone == 'Australia') {
      this.setState({
        zones: Australia,
        zone: Australia[0].id
      })
    } else if (zone == 'Europe') {
      this.setState({
        zones: Europe,
        zone: Europe[0].id
      })
    } else if (zone == 'Indian') {
      this.setState({
        zones: Indian,
        zone: Indian[0].id
      })
    }
    this.setState({
      main: zone,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.push('/campaign/list')}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
             <AvForm
                    onValidSubmit={this.handleSubmit}
                    // model={this.state.record}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <ModalBody className="row">
                      <div className="col-md-12 mb-3">
                        <AvField
                          name="title"
                          value={this.state.record.title}
                          label="Title"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3 d-none">
                        <label>Main Zone</label>
                        <div className="MainTZone Campaign One">
                          <Select
                            getOptionLabel={(option) => option}
                            getOptionValue={(option) => option}
                            options={this.state.Timezone}
                            value={this.state.Timezone.filter((id) => id == this.state.main)}
                            isMulti={false}
                            classNamePrefix="select2-selection"
                            name="zone"
                            onChange={(e) => this.changeMainZone(e)}
                          />
                        </div>
                        <AvField type="hidden" name="main_zone" value={this.state.main} />
                      </div>

                      <div className="col-md-3 mb-3 d-none">
                        <label>Time Zone</label>
                        <div className="MainTZone Campaign Two">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.id}
                            options={this.state.zones}
                            value={this.state.zones.filter(({ id }) => id === this.state.zone)}
                            isMulti={false}
                            classNamePrefix="select2-selection"
                            name="timezone"
                            onChange={(e) => this.changeTimeZone(e.id)}
                          />
                        </div>
                        <AvField type="hidden" name="time_zone" value={this.state.zone} />
                      </div>
                      <div className="col-md-6 mb-3 d-none">
                        <label className="control-label"> Group </label>
                        <div className="MainTZone Campaign Three">
                          <Select
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.group_id}
                            isMulti={true}
                            value={this.state.selectedGroup}
                            options={this.state.groups}
                            classNamePrefix="select2-selection"
                            name="groups_id"
                            onChange={(e) => {
                              this.handleGroup(e);
                            }}
                          />
                        </div>
                        <AvField
                          
                          type="hidden"
                          name="group_id"
                          value={this.state.group_id}
                        />
                      </div>
                      <div className="col-md-6 mb-3 d-none">
                        <label className="control-label"> Tags </label>
                        <div className="MainTZone Campaign Four">
                          <Select
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.group_id}
                            isMulti={true}
                            value={this.state.selectedTags}
                            options={this.state.tags}
                            classNamePrefix="select2-selection"
                            name="country_id"
                            onChange={(e) => {
                              this.handleTag(e);
                            }}
                          />
                        </div>
                        <AvField
                          type="hidden"
                          name="tag_id"
                          value={this.state.tag_id}
                        />
                      </div>
                      <div className="col-md-3 mb-3 d-none">
                        <AvField
                          name="from_name"
                          value={this.state.record.from_name}
                          label="From Name"
                          className="form-control"
                          
                        />
                      </div>
                      <div className="col-md-3 mb-3 d-none">
                        <AvField
                          name="from_email"
                          value={this.state.record.from_email}
                          label="From Email"
                          className="form-control"
                          
                        />
                      </div>
                      <div className="col-md-11 mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="phone_number"
                          label=" Caller ID"
                          value={this.state.record.phone_number}
                        >
                          <option value=""> Select Caller ID </option>
                          {this.state.phoneNumbers.map((row, i) => {
                            return (
                              
                                <option key={i} value={row.phone_number}>
                                  {convertPhoneFormat(row.phone_number)}
                                </option>
                              
                            );
                          })}
                        </AvField>
                      </div>
                      <div className="col-md-1 mb-3">
                          <div class="form-group"><label for="phone_number" class=""></label></div>
                          <button
                                  onClick={() => {
                                      this.props.history.push('/caller/numbers/list')
                                  }}
                                  type="button"
                                  className="btn btn-md btn-primary mt-2"
                              >
                                 <i className="mdi mdi-plus"> </i>
                          </button>
                      </div>
                      <div className="col-md-12 mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="dial_type"
                          label={
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="button-tooltip">
                                  An <b>Auto Dialer</b> dials phone numbers sequentially, while a <b>Predictive Dialer</b> can call multiple numbers simultaneously based on the chosen dialing rate per agent.
                                </Tooltip>
                              }
                            >
                              <span>Dial Type <i className='fas fa-info'></i></span>
                            </OverlayTrigger>
                          }
                          value={this.state.record.dial_type}
                          onChange={(e) => this.setState({ record: { ...this.state.record, dial_type: e.target.value } })}
                        >
                          <option value="auto_dialer">Auto Dialer</option>
                          <option value="predictive_dialer">Predictive Dialer</option>
                        </AvField>
                      </div>
                      <div className="col-md-12 mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="dial_level"
                          label="Dial Level Per Agent"
                          value={this.state.record.dial_level}
                          onChange={(e) => this.setState({ record: { ...this.state.record, dial_level: e.target.value } })}
                        >
                          {this.state.record.dial_type == 'auto_dialer' ? (
                            <option value="1">auto</option>
                          ) : this.state.record.dial_type == 'predictive_dialer' ? (
                            <>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </>
                          ) : null}
                        </AvField>
                      </div>
                      <div className="col-md-12 mb-3">
                          <AvField
                              name="drop_call_after"
                              value={this.state.record.drop_call_after}
                              label="Drop Call After (sec)"
                              placeholder="Enter seconds"
                              className="form-control"
                          />
                      </div>
                      <div className="col-md-11 mb-3">
                          <label className="control-label">Teams</label>
                              <Select
                                  getOptionLabel={option => option.name}
                                  getOptionValue={option => option.id}
                                  isMulti={true}
                                  value={this.state.selectedTeam}
                                  options={this.state.teams}
                                  classNamePrefix="select2-selection"
                                  name="team_id"
                                  onChange={(e) => {
                                      this.handleTeam(e)
                                  }}
                              />
                              <AvField required type="hidden" name="team_id" value={this.state.team_id} />
                          </div>
                      <div className="col-md-1 mb-3">
                          <div class="form-group"><label for="phone_number" class=""></label></div>
                          <button
                                  onClick={() => {
                                      this.props.history.push('/teams/add')
                                  }}
                                  type="button"
                                  className="btn btn-md btn-primary mt-2"
                              >
                                 <i className="mdi mdi-plus"> </i>
                          </button>
                      </div>
                      <div className="col-md-11 mb-3">
                          <AvField
                              className="select form-control"
                              type="select"
                              name="script_id"
                              label="Agent Script"
                              value={this.state.record.script_id}
                              required
                          >
                              <option value=""> Select Script </option>
                              {
                                  this.state.scripts.map((row, i) => {
                                      return (
                                          <option key={i} value={row.id} > {row.name}</option>
                                      );
                                  })
                              }
                          </AvField>
                      </div>
                      <div className="col-md-1 mb-3">
                          <div class="form-group"><label for="phone_number" class=""></label></div>
                          <button
                                  onClick={() => {
                                      this.props.history.push('/agent/script/list')
                                  }}
                                  type="button"
                                  className="btn btn-md btn-primary mt-2"
                              >
                                 <i className="mdi mdi-plus"> </i>
                          </button>
                      </div>
                      <div className="col-md-12 mb-3">
                          <div className="form-group">
                              
                            <AvGroup check>
                              <AvInput 
                                type="checkbox" 
                                name="redialing_rule" 
                                checked={this.state.record.redialing_rule === 'Yes'} 
                                onChange={(e) => this.setState({
                                  record: {
                                    ...this.state.record,
                                    redialing_rule: e.target.checked ? 'Yes' : 'No',
                                  },
                                })}
                              />
                              <Label check htmlFor="checkbox"> Enable Redialing Rule</Label>
                            </AvGroup>

                          </div>
                      </div>
                      <div className="col-md-6 mb-3 d-none">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="throttle_duration"
                          label="Throttle duration"
                          required
                          value={this.state.record.throttle_duration}
                        >
                          <option value="15"> 15 minutes </option>
                          <option value="30"> 30 minutes </option>
                          <option value="60"> 60 minutes </option>
                        </AvField>
                      </div>
                      <div className="col-md-6 mb-3 d-none">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="throttle_value"
                          label="Throttle value"
                          required
                          value={this.state.record.throttle_value}
                        >
                          <option value="5"> 5 </option>
                          <option value="10"> 10 </option>
                          <option value="15"> 15 </option>
                          <option value="25"> 25 </option>
                          <option value="50"> 50 </option>
                          <option value="100"> 100 </option>
                          <option value="150"> 150 </option>
                          <option value="200"> 200 </option>
                          <option value="250"> 250 </option>
                          <option value="300"> 300 </option>
                          <option value="350"> 350 </option>
                          <option value="400"> 400 </option>
                          <option value="450"> 450 </option>
                          <option value="500"> 500 </option>
                        </AvField>
                      </div>
                      {/* offset-2 */}
                      <div className="col-md-12 mb-3">
                        <AvField
                          name="status"
                          value={this.state.record.status}
                          label="Status"
                          className="form-control"
                          id="rs"
                          required
                          type="select"
                        >
                          <option value={''}>Select Status</option>
                          <option value={'Active'}>Active</option>
                          <option value={'Inactive'}>Inactive</option>
                          {/* <option value={'Hidden'}>Hidden</option> */}

                        </AvField>
                      </div>

                      <div className="col-md-6 mb-3 d-none">
                        <label className="control-label"> Assign to </label>
                        <div className="MainTZone Campaign Five">
                          <Select
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.user_id}
                            isMulti={true}
                            value={this.state.selectedPermission}
                            options={this.state.permissions}
                            classNamePrefix="select2-selection"
                            onChange={(e) => {
                              this.handlePermission(e);
                            }}
                          />
                        </div>
                        <AvField
                          type="hidden"
                          name="permission"
                          value={this.state.permission}
                        />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </AvForm>
               



            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
