import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Table,
    Alert,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    hasRole,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import swal from 'sweetalert';
import Badge from "react-bootstrap/Badge";
import Dropdown from 'react-bootstrap/Dropdown';

export default class Campaign extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Campaigns",
                key: "title",
            },
           {
              key: "action",
              text: "Action",
              cell: (record, index) => {
                  return (
                      <Fragment>
                       { record.group_status == 'Active' ? (
                          <button
                              type="button"
                              className="btn btn-success"
                              onClick={() => {
                                setTimeout(
                                    () => this.props.history.push(`/campaign/start?id=${bcrypt(record.campaign_id)}`),
                                    1000
                                );
                              }}
                          >
                              Join Campaign
                          </button>
                        ):(
                            <button
                              type="button"
                              className="btn btn-success"
                               disabled={true}
                          >
                              Join Campaign
                          </button>
                        )}
                      </Fragment>
                  );
              }
          },

        ];
        this.config = {
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: false,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            campaign_log: {},
            deal_status: {},
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Campaign",
            customDate: false,
            ClientSubcribeModal: false,
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
            expandedRows: '',
            expandState: {}
        };
    }
    logoutAgents = (record, index) => {
        let url = `${ApiUrl}` + "update/groupleads/" + record.group_id;

        fetch(`${url}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              //this.getData();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    };
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/campaign", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.campaign_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                //               toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "campaigns" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getCampaignLog = (campaign_id) => {
        fetch(`${ApiUrl}` + "campaign/report/log", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: campaign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        campaign_log: response.data.campaign_log,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    resetDialAttempt = (campaign_id) => {
        fetch(`${ApiUrl}` + "campaign/reset/attempt", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: campaign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/campaign/list',
                        });
                    }, 2000);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "campaigns" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };



    handleEpandRow = (event, campaign_id) => {


        const currentExpandedRows = this.state.expandedRows;

        const isRowExpanded = currentExpandedRows == campaign_id ? true : false
        console.log('newExpandedRows', isRowExpanded)

        let obj = {};
        isRowExpanded ? (obj[campaign_id] = false) : (obj[campaign_id] = true);

        this.setState({
            expandState: obj
        })

        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ? currentExpandedRows !== campaign_id :
            campaign_id

        console.log(newExpandedRows, 'newExpandedRows', isRowExpanded)

        this.setState({ expandedRows: newExpandedRows })
        this.getCampaignLog(campaign_id);

    }

    render() {
        const queryParams = this.props.location.state ? this.props.location.state : '';
        const sessionMSg = queryParams.msg ? queryParams.msg : '';
        const sessionCampaignId = queryParams.campaign_id ? queryParams.campaign_id : 0;

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>


                <ToastContainer />


            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </React.Fragment>
        );
    }
}
