import React, { useEffect, useState } from "react";

import { Link, Redirect, useHistory } from "react-router-dom";

// reactstrap
import {
    Row,
    Col,
    Container,
} from "reactstrap";


import { ApiUrl, logoLightPng } from "../../../config.js";
import { bcrypt, uInfo, uToken } from "../../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toast, ToastContainer, Flip } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import AvField from "availity-reactstrap-validation/lib/AvField";
import ReactSelect from "react-select";
import countries from "../../../common/data/countries";
import Incall from "../../Incall";
//i18n

const Header = (props) => {
    const [menu, setMenu] = useState(false);
    const [isSearch, setSearch] = useState(false);
    const [socialDrp, setsocialDrp] = useState(false);

    const [show, setShow] = useState(false);
    const [existing, setExisting] = useState([]);
    const [isClient, setIsClient] = useState(true);

    const [countryCode, setCountryCode] = useState("");
    const [groupId, setGroupId] = useState("");
    const [groups, setGroups] = useState([]);
    let history = useHistory();

    useEffect(() => {
        getGroups();
    }, []);

    const searchClient = (search) => {
        if (search != "") {
            fetch(`${ApiUrl}` + "get/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ filter_value: search, campaign_id: props.campaign.campaign_id }),
            })
                .then((response) => response.json())
                //Then with the data from the response in JSON...
                .then((response) => {
                    // console.log(response, "data");
                    if (response.status === true) {
                        setExisting(response.data.records);
                        setIsClient(response.data.records.length > 0 ? true : false);
                    } else {
                         toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setExisting([]);
            setIsClient(true);
        }
    };
    const getGroups = () => {
        fetch(`${ApiUrl}` + "groups", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setGroups(response.data.records);
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event, values) => {
        // props.navigation.history.push("/client/list");

        fetch(`${ApiUrl}` + "edit/client", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {

                if (data.status === true) {
                    let res = data.data;
                    //console.log(res.client_id, 'client_id');
                    // props.navigation.history.push(
                    //     "/client/dialer/" +
                    //     bcrypt(res.client_id) +
                    //     "/" +
                    //     bcrypt(res.coach_id)
                    // );
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleCountry = (selectedCountry) => {
        setCountryCode(selectedCountry.code);
    };

    const handleGroup = (selectedGroup) => {
        setGroupId(selectedGroup.group_id);
    };

    

    return (
        <Row>
      <Col md={12} style={{ height: '73px' }}>
        <Row>
          <Col md={6}>
            <div
              className="card"
              style={{
                border: 'none',
                borderRadius: '10px',
                transition: 'transform 0.3s',
                transform: 'none',
              }}
            >
              <div className="card-body">
                <h6
                  className="card-subtitle"
                  style={{
                    fontSize: '1rem',
                    color: 'rgb(108, 117, 125)',
                    marginBottom: '1rem',
                  }}
                >
                  <i className="fas fa-clock me-2"></i>
                  10:36:57
                </h6>
              </div>
            </div>
          </Col>
          <Col md={6} className="d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center">
              <div className="me-3">
                {uInfo().name}
              </div>
              <button
                className="btn btn-primary rounded-pill"
                onClick={() =>
                  history.push({
                    pathname: '/dashboard',
                    state: { back: 'logout' },
                  })
                }
              >
                <i className="fas fa-power-off"></i>
              </button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12}>
        <Incall />
      </Col>
    </Row>
    );
};

export default Header;
